import React, { useState } from "react"
import { Link } from "gatsby"
import { Tabs } from 'antd'
import { FormattedMessage } from "react-intl"

import Seo from '../components/seo'
import Layout from "../components/layout"
import Icon from '../components/iconfontCpt'

import '../style/zcloud.scoped.scss'

const ZCLOUD = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''
  const { TabPane } = Tabs
  const [type, setType] = useState('source')
  return (
    <Layout pageLang={ pageLang } showHeader={true} showFooter={true} useTransparentTheme={true} customClass="use-translucent-theme">
    <Seo title="MogDB: MDB"/>
      <div className="page-container">
        <div className="zcloud-banner">
          <div className="product-banner-box">
            <h1 className="product-banner-title"><FormattedMessage id="zcloud.pageTitle" /><i className="zcloud"></i></h1>
            <div className="product-banner-desc">
              <p><FormattedMessage id="zcloud.pageContent" /></p>
            </div>
            <div className="btn-group">
            <Link to={`${pagePrefix}/downloads/zcloudForMogdb/all/`} className="btn download"><FormattedMessage id="index.download" /></Link>
              <a href={`https://docs.mogdb.io/${pageLang}/zcloud-for-mogdb/latest/overview`} rel="noreferrer" target="_blank" className="btn searchDoc"><FormattedMessage id="index.docs" /></a>
            </div>
          </div>
        </div>

        <div className="zcloud-feature">
          <div className="tip-box">
            <div>
              <div className="introduce-icon">
                <Icon type="icon-architecture" size={70}/>
              </div>
              <div className="feature-title"><FormattedMessage id="zcloud.feature1" /></div>
              <div className="feature-des"><FormattedMessage id="zcloud.feature1Desc" /></div>
            </div>
            <div>
              <div className="introduce-icon">
                <Icon type="icon-assessment" size={70}/>
              </div>
              <div className="feature-title"><FormattedMessage id="zcloud.feature2" /></div>
              <div className="feature-des"><FormattedMessage id="zcloud.feature2Desc" /></div>
            </div>
            <div>
              <div className="introduce-icon">
                <Icon type="icon-acquisition" size={70}/>
              </div>
              <div className="feature-title"><FormattedMessage id="zcloud.feature3" /></div>
              <div className="feature-des"><FormattedMessage id="zcloud.feature3Desc" /></div>
            </div>
          </div>
        </div>

        <h2 className="async-title-box"><FormattedMessage id="zcloud.design" /></h2>
        <div className="scene-container">
          <div className="scene-box">
            <h3 className="scene-title"><FormattedMessage id="zcloud.design1" /></h3>
            <p className="scene-des"><FormattedMessage id="zcloud.design1Desc" /></p>
          </div>
          <div className="scene-box">
            <h3 className="scene-title"><FormattedMessage id="zcloud.design2" /></h3>
            <p className="scene-des"><FormattedMessage id="zcloud.design2Desc" /></p>
          </div>
          <div className="scene-box">
            <h3 className="scene-title"><FormattedMessage id="zcloud.design3" /></h3>
            <p className="scene-des"><FormattedMessage id="zcloud.design3Desc" /></p>
          </div>
          <div className="scene-box">
            <h3 className="scene-title"><FormattedMessage id="zcloud.design4" /></h3>
            <p className="scene-des"><FormattedMessage id="zcloud.design4Desc" /></p>
          </div>
          <div className="scene-box">
            <h3 className="scene-title"><FormattedMessage id="zcloud.design5" /></h3>
            <p className="scene-des"><FormattedMessage id="zcloud.design5Desc" /></p>
          </div>
        </div>

        <h2 className="framework-title-box"><FormattedMessage id="zcloud.framework" /></h2>
        <div className="framework"></div>
        
        <div className="experience">
          <div>
            <div className="e-title"><FormattedMessage id="index.experience" /></div>
            <div className="e-content"><FormattedMessage id="zcloud.experienceContent" /></div>
            <div className="btn-group">
              {/* <a href={`https://docs.mogdb.io/${pageLang}/mdb/latest/release`} target="_blank" className="btn"><FormattedMessage id="index.useNow" /></a> */}
              <Link to={`${pagePrefix}/contact`} className="btn download"><FormattedMessage id="index.contactUs" /></Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default ZCLOUD